import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ORDER_STATUSES} from "./constants/status";
import slugify from "utils/slugify";
import CreditCardInfo from "components/CreditCardInfo";
import OrderTotals from "modules/MyAccount/scenes/OrderHistory/components/Order/components/OrderTotals/index";
import {
  MODAL_CANCEL,
  MODAL_INVOICE,
  MODAL_RETURN_ITEMS,
  MODAL_TRACKING
} from "modules/MyAccount/scenes/OrderHistory/constants/modal-names";
import OrderItemList from "modules/MyAccount/scenes/OrderHistory/components/Order/components/OrderItemList/index";
import {isAuthenticated} from "services/auth/index";
import {
  ORDER_AWAITING_PAYMENT,
  ORDER_AWAITING_PAYMENT_EXECUTION,
  ORDER_COMPLETED,
  ORDER_CONFIRMED,
  ORDER_PARTIALLY_REFUNDED,
  ORDER_PROCESSING
} from "modules/MyAccount/scenes/OrderHistory/components/Order/constants/status";

class Order extends Component {
  constructor(props) {
    super(props);

    this.openModal = name => this._openModal.bind(this, name);
  }

  static getStatusDisplay(status) {
    for (let [key, value] of ORDER_STATUSES)
      if (key === status) return value;
  }

  /**
   * Open a modal in the redux store
   * @param {String} name
   * @private
   */
  _openModal(name) {
    this.props.openModal(name, this.props.order);
  }

  /**
   * Get modals to show
   */
  get getModals() {
    const {order, orderHistory} = this.props;

    let modalsToShow = [
      MODAL_INVOICE
    ];

    if ([ORDER_COMPLETED, ORDER_PARTIALLY_REFUNDED].indexOf(order.status) > -1)
      modalsToShow.push(MODAL_RETURN_ITEMS);

    if ([ORDER_AWAITING_PAYMENT, ORDER_CONFIRMED, ORDER_PROCESSING, ORDER_AWAITING_PAYMENT_EXECUTION].indexOf(order.status) > -1)
      modalsToShow.push(MODAL_CANCEL);

    if (order.shipments.length > 0)
      modalsToShow.push(MODAL_TRACKING);

    return orderHistory.modals.filter(modal => modalsToShow.indexOf(modal.name) > -1);
  }

  render() {
    const {order, orderHistory} = this.props;
    const modalsForThisOrder = isAuthenticated()
        ? this.getModals
        : [orderHistory.modals.find(modal => modal.name === MODAL_INVOICE)];

    return (
        <div className="order">
          <div className="row">
            <div className="col-md-4">
              <div className={`status ${slugify(Order.getStatusDisplay(order.status))}`}>
                <span className="circle"/>
                <span className="text">{Order.getStatusDisplay(order.status)}</span>
              </div>
              <OrderItemList
                  itemList={order.items.normal}/>
              <OrderItemList
                  itemList={order.items.cancelled}/>
              <OrderItemList
                  itemList={order.items.returned}/>
              <OrderItemList
                  itemList={order.items.shipment}/>
            </div>
            <div className="col-md-4">
              <p>Order No. <span>{order.uuid}</span></p>
              <div className="payment-info">
                <h3>Credit Card</h3>
                <p>
                  <CreditCardInfo
                      creditCard={order.credit_card_data}/>
                </p>
              </div>
              <div className="shipping-info">
                <h3>Shipping to:</h3>
                <p>
                  {order.shipping_address_data.address_1}<br/>
                  {order.shipping_address_data.city_name}, {order.shipping_address_data.region_name}<br/>
                  {order.shipping_address_data.country_code} {order.shipping_address_data.zip_code}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <p>
                Date:&nbsp;
                <span>
                  {`${new Date(order.created_at).toDateString()}`}
                </span>
              </p>
              <div className="summary">
                <h3>Order Summary</h3>
                <div className="table-responsive">
                  <OrderTotals
                      order={order}/>
                </div>
              </div>
            </div>
          </div>
          <div className="options">
            {
              modalsForThisOrder.map((modal, index) =>
                  <button
                      key={index}
                      onClick={this.openModal(modal.name)}
                      style={{
                        padding: 0,
                        border: "none"
                      }}>
                    <span className={modal.buttonClassName}>
                      {modal.name}
                    </span>
                  </button>
              )
            }
          </div>
        </div>
    )
  }
}

Order.propTypes = {
  order: PropTypes.object.isRequired
};

export default Order