import { connect } from 'react-redux';
import CartItemList from "../index";
import {fetchCartItems} from "modules/store/actions";

const mapStateToProps = (state) => {
  return {
    store: state.store
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCartItems: () => dispatch(fetchCartItems())
  };
};

const HeaderCartContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CartItemList);

export default HeaderCartContainer;