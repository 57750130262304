import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AddToCartButtonContainer from "modules/store/scenes/ProductDetails/components/AddToCartButton/container";
import {USED} from "modules/store/scenes/ProductsList/components/Product/constants/conditions";
import QuantityInput from "utils/components/QuantityInput/index";
import vintageAxios from "services/api/index";
import {getWishListId} from "services/store/wishlist/index";
import debounce from "lodash/debounce";

class WishListItem extends Component {
  // TODO PRODUCT_PRICE_SELECTOR <ProductPriceSelector/> will be deprecated, remove from this component
  constructor(props) {
    super(props);

    const {item} = this.props;

    this.state = {
      deleting: false,
      quantity: item.quantity,
    };

    this.handleQuantityChanges = this._handleQuantityChanges.bind(this);
  }

  deleteWishListItem() {
    this.setState({deleting: true}, () => {
      let promise = this.props.deleteWishListItem(this.props.item);
      promise.catch(error => {
        this.setState({deleting: false});
      });
    });
  }

  updateQuantity() {
    const {item} = this.props;
    const wishListId = getWishListId();
    const {quantity} = this.state;
    const promise = vintageAxios.patch(`/management/wish-lists/${wishListId}/items/${item.id}/`, {
      quantity: quantity
    });
    promise.then(response => {
      this.props.updateWishListItem(response.data);
    });
    return promise;
  }

  debounceUpdateQuantity = debounce(this.updateQuantity, 500);

  /**
   * Handles changes on QuantityInput component.
   * @param {Number} quantity
   * @private
   */
  _handleQuantityChanges(quantity) {
    this.setState({
      quantity: quantity
    }, () => {
      const wishListId = getWishListId();
      if (wishListId)
        this.debounceUpdateQuantity();
    });
  }

  render() {
    const {item, readOnly} = this.props;
    const {quantity} = this.state;

    return (
        <div className="wish-item">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="product clearfix">
                <div className="photo">
                  <img src={item.profile_picture} alt={item.product_name}/>
                </div>
                <div className="info">
                  <p>{item.product_name}</p>
                  <div className="price">
                    <span>Price:</span>
                    {
                      item.condition === USED ? (
                          <span className="used">${item.product_price_used}</span>
                      ) : (
                          <span className="new">${item.product_price_new}</span>
                      )
                    }
                    <span className="price__condition">{item.condition === USED ? 'USED' : 'NEW'}</span>
                  </div>
                  {
                    item.condition === USED &&
                    <div className="box">
                      <p>
                        <span className="icon icon-circle-check"/>
                        <span>
                          100% Quality Guaranteed
                        </span>
                      </p>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="right">
                <div className="input">
                  <label htmlFor="qty1">Qty:</label>
                  <QuantityInput
                      quantity={quantity}
                      condition={item.condition}
                      stockNew={item.product_total_stock_new}
                      stockUsed={item.product_total_stock_used}
                      onQuantityChanges={this.handleQuantityChanges}/>
                </div>
                <div className="options">
                  <AddToCartButtonContainer
                      contentTypeId={item.content_type}
                      productId={item.object_id}
                      quantity={this.state.quantity}
                      productData={{
                        sku: item.product_sku,
                        name: item.product_name
                      }}
                      condition={item.condition}
                      look="link"
                      size="small"/>
                  {
                    !readOnly &&
                    <button
                        style={{
                          border: 'none'
                        }}
                        onClick={() => this.deleteWishListItem()}
                        aria-label={`remove ${item.product_name}`}
                        disabled={this.state.deleting}>
                      <span className="icon-rubbish"/>
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

WishListItem.defaultProps = {
  readOnly: false
};

WishListItem.propTypes = {
  item: PropTypes.object.isRequired,
  readOnly: PropTypes.bool
};

export default WishListItem
