import React, {useState} from "react";
import {Panel, PanelGroup} from "react-bootstrap";
import {NEW, USED} from "modules/store/scenes/ProductsList/components/Product/constants/conditions";
import {useHistory, useLocation} from "react-router-dom";
import QueryParams from "utils/QueryParams";
import {QUERY_PARAM_PRODUCT_CONDITION} from "modules/store/scenes/ProductsList/constants/query-params";
import {parseCondition} from "utils/products"

export default function SidebarConditions() {
  const location = useLocation()
  const history = useHistory()
  const queryParams = new QueryParams(location.search)
  const [selectedCondition, setSelectedCondition] = useState(parseCondition(queryParams.find(QUERY_PARAM_PRODUCT_CONDITION)))

  const conditions = [
    {
      name: "All",
      slug: ""
    },
    {
      name: "New",
      slug: NEW
    },
    {
      name: "Used",
      slug: USED
    }
  ];

  function handleChange(condition) {
    setSelectedCondition(condition.slug);
    queryParams.update({
      [QUERY_PARAM_PRODUCT_CONDITION]: condition.slug.toString()
    });
    history.push({
      pathname: "/store/products",
      search: queryParams.asSearchString
    });
  }

  return (
      <React.Fragment>
        <div className="title dark">
          <h6>Narrow By</h6>
        </div>
        <div>
          <PanelGroup
              id="new_or_used"
              defaultActiveKey="1" accordion>
            <Panel
                eventKey="1"
                header={<h6>New or Used<i className="fa fa-caret-up"/></h6>}>
              <fieldset>
                {
                  conditions.map(condition => (
                      <p>
                        <input
                            type="radio"
                            data-toggle="tab"
                            data-name={condition.name}
                            id={condition.name}
                            onChange={() => handleChange(condition)}
                            name="productStatus"
                            value={condition.slug}
                            checked={selectedCondition === condition.slug}/>
                        <label htmlFor={condition.name}>
                          <span className="text">&nbsp;&nbsp;{condition.name}</span>
                        </label>
                      </p>
                  ))
                }
              </fieldset>
            </Panel>
          </PanelGroup>
        </div>
      </React.Fragment>
  );
}