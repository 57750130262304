import React, {PureComponent} from 'react';
import "./styles/PrivacyPolicy.scss";

class PrivacyPolicy extends PureComponent {
  render() {
    return (
        <div className="privacy-policy">
          <h1>
            Privacy Policy
          </h1>
          <p>
            Last Modified: June 5, 2019
          </p>
          <p>
            Vintage Stock, Inc. (“Company”, “we”, “us”, or “our”) respects your privacy
            and is committed to protecting it. This privacy policy (the “Privacy
            Policy”) applies to your information we collect in the United States at
            Company stores (“Stores”) and through your use of our websites (including
            without limitation, <a href="http://www.vintagestock.com">www.vintagestock.com</a>,
            <a href="http://www.movietradingcompany.com">
              www.movietradingcompany.com
            </a>
            , and <a href="http://www.entertainmart.com">www.entertainmart.com</a>) and
            other Company online products and services, such as emails and other
            electronic messages between you and our Company (collectively, the
            “Websites”).
          </p>
          <p>
            This Privacy Policy does not apply to information collected through any
            other website operated by any third party (including third party websites
            and mobile applications you may access through the Websites) nor does it
            apply to information collected by any third party including through any
            application or content (including advertising) that may link to or be
            accessible from or through the Websites.
          </p>
          <p>
            Please read this policy carefully to understand our policies and practices
            regarding your information and how we will treat it. By interacting with
            our Company through our Stores, Websites, applications, products, and
            services, you consent to the use of information that is collected or
            submitted as described in this Privacy Policy and agree to our terms of use
            located at
            <a href="https://vintagestock.com/about/terms-of-use">
              https://vintagestock.com/about/terms-of-use
            </a>
            (“Terms of Use”). This policy may change from time to time. Your continued
            use of the Websites after we make changes is deemed to be acceptance of
            those changes, so please check this policy periodically for updates.
          </p>
          <h2>
            Information We Collect About You and How We Collect It.
          </h2>
          <p>
            We collect several types of information, including information (a) by which
            you may be personally identified, such as name, mailing address, e-mail
            address, telephone number, date of birth or age, driver’s license number,
            credit/debit card number, purchase/return/exchange information, and other
            information that may be collected from our Stores or Websites that is
            defined as personal or personally identifiable information under an
            applicable law ("Personal Information"); and (b) about your shopping
            preferences, internet connection, IP address, geo-location and in-store
            location, the equipment you use to access our Websites, and usage details.
          </p>
          <p>
            We collect this information (a) directly from you when you provide it to
            us, including when you provide data at any point of sale terminal at our
            Stores, fill in forms on our Website (such as setting up an account,
            purchasing goods, or requesting services through our Website), respond to
            text or email messages from our Company, or respond to surveys or promotion
            offers; (b) automatically as you navigate through the Website, including
            information collected through cookies, web beacons, and other tracking
            technologies (described in more detail below); and (c) from third parties,
            for example, our business partners and service providers. We may connect
            information we already have about you in order to identify you as a Company
            guest. If we are able to identify you as a Company guest we may, for
            example, link your activity on our Website to your activity in a Store.
            This allows us to provide a personalized experience regardless of how you
            interact with us.
          </p>
          <p>
            You also may provide information to be published or displayed (hereinafter,
            "posted") on public areas of the Websites (including product feedback and
            message boards) or transmitted to other users of the Websites or third
            parties (collectively, "User Contributions"). Your User Contributions are
            posted on and transmitted to others at your own risk. Please be aware that
            no security measures are perfect or impenetrable. Additionally, we cannot
            control the actions of other users of the Websites with whom you may choose
            to share your User Contributions. Therefore, we cannot and do not guarantee
            that your User Contributions will not be viewed by unauthorized persons.
          </p>
          <p>
            Please be advised that if you choose not to provide information, we may not
            be able to provide you with requested products, services, or information.
          </p>
          <h2>
            Information We Collect Through Automatic Data Collection Technologies.
          </h2>
          <p>
            As you navigate through and interact with our Websites, we may use
            automatic data collection technologies to collect certain information about
            your equipment, browsing actions, and patterns, including (a) details of
            your visits to our Websites, including traffic data, location data, logs,
            and other communication data and the resources that you access and use on
            our Websites; and (b) information about your computer and internet
            connection, including your IP address, operating system, mobile device, and
            browser type.
          </p>
          <p>
            We may maintain or associate data automatically collected with information
            we collect in other ways or receive from third parties. It helps us to
            improve our Websites, Stores, and to deliver a better and more personalized
            service, including by enabling us to (a) estimate our audience size and
            usage patterns; (b) store information about your preferences, allowing us
            to customize our Websites, Stores, and correspondences with you according
            to your individual interests and geographical location; and (c) recognize
            you when you return to our Websites and Stores.
          </p>
          <h2>
            The technologies we use for automatic data collection may include:
          </h2>
          <ul>
            <li dir="ltr">
              Cookies (or browser cookies). A cookie is a small file placed on
              the hard drive of your computer. You may refuse to accept browser
              cookies by activating the appropriate setting on your browser, but
              if you select this setting you may be unable to access certain
              parts of our Websites. Unless you have adjusted your browser
              setting so that it will refuse cookies, our system will issue
              cookies when you direct your browser to our Websites. For more
              information about managing your privacy and security settings for
              cookies, see <a href="http://www.aboutcookies.org">www.aboutcookies.org</a>.
            </li>
          </ul>
          <ul>
            <li dir="ltr">
              Flash Cookies. Certain features of our Websites may use local
              stored objects (or Flash cookies) to collect and store information
              about your preferences and navigation to, from and on our Websites.
              Flash cookies are not managed by the same browser settings as are
              used for browser cookies. For information about managing your
              privacy and security settings for Flash cookies, see <a
                href="http://www.aboutcookies.org">www.aboutcookies.org</a>.
            </li>
          </ul>
          <ul>
            <li dir="ltr">
              Web Beacons. Pages of our Websites may contain small electronic
              files known as web beacons (also referred to as clear gifs. pixel
              tags and single-pixel gifs) that permit the Company, for example,
              to count users who have visited those pages or opened an e-mail and
              for other related statistics (for example, recording the popularity
              of certain content and verifying system and server integrity).
            </li>
          </ul>
          <p>
            We do not collect Personal Information automatically, but we may tie any
            automatically collected information to Personal Information about you that
            we collect from other sources or you provide to us.
          </p>
          <h2>
            Third-party Use of Cookies and Other Tracking Technologies.
          </h2>
          <p>
            Some content or applications, including advertisements, on the Websites are
            serviced or provided by third-parties, including advertisers, ad networks
            and servers, content providers, and application providers. These third
            parties may use cookies alone or in conjunction with web beacons or other
            tracking technologies to collect information about you when you use our
            Websites. The information they collect may be associated with your Personal
            Information or they may collect information, including Personal
            Information, about your online activities over time and across different
            websites and other online Websites. They may use this information to
            provide you with interest-based (behavioral) advertising or other targeted
            content. We do not control these third parties' tracking technologies or
            how they may be used. If you have any questions about an advertisement or
            other targeted content, you should contact the responsible provider
            directly.
          </p>
          <h2>
            Links to Other Sites.
          </h2>
          <p>
            We may provide links to other websites that we think you may enjoy. These
            websites operate independently of our Company Websites and have established
            their own privacy and security policies. For the best online experience, we
            encourage you to review these policies before submitting any Personal
            Information through these websites.
          </p>
          <h2>
            Social Media Ads.
          </h2>
          <p>
            We may display targeted ads to you through social media platforms. These
            ads are sent to groups of people who share traits such as likely commercial
            interests and demographics. For example, we may target guests who have
            expressed an interest in shopping for video games or new release movies.
            See the policies of each social media platform for additional information
            about these types of ads.
          </p>
          <h2>
            Store Cameras.
          </h2>
          <p>
            We use cameras in and around our stores for security purposes and for
            operational purposes such as measuring traffic patterns and tracking
            in-stock levels. Cameras in some stores may use biometrics, including
            facial recognition for fraud and theft prevention and security.
          </p>
          <h2>
            How We Use Your Information.
          </h2>
          <p>
            We will not share, rent or sell your Personal Information to other companies or individuals, unless we have your consent.
            We use information that we collect about you or that you provide to us,
            including any Personal Information (a) to present our Websites and its
            contents to you; (b) to provide you with information, products, or services
            that you request from us, including to facilitate order fulfillment and
            sale transactions; (c) to provide you with promotional offers and
            advertisements, including through text message and email; (d) to recognize
            you when you return to our Websites and Stores, including to populate your
            customer account(s); (e) to aggregate data; (f) to fulfill any other
            purpose for which you provide it; (g) to provide you with notices about
            your account or subscription, including expiration and renewal notices; (h)
            to carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing, payment,
            and collection; (i) to notify you about changes to our Websites; (j) to
            share your information with our service providers and third party
            application providers; (k) to allow you to participate in interactive
            features on our Websites and mobile applications; (l) in any other way we
            may describe when you provide the information; and (m) for any other
            purpose with your consent and consistent with applicable law.
          </p>
          <h2>
            Disclosure of Your Information.
          </h2>
          <p>
            We may disclose de-identified aggregated information about our users, and
            information that does not identify any individual (including de-identified
            information) for any lawful purpose. We may also disclose information that
            we collect from you or you provide as described in this Privacy Policy,
            including any Personal Information (a) to our affiliates; (b) to our
            service providers and other third parties we use to support our business,
            including our payment processing and billing application providers; (c) to
            a buyer or other successor in the event of a merger, divestiture,
            restructuring, reorganization, dissolution, or other sale or transfer of
            some or all of the our assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding, in which Personal
            Information held by the us about our Websites users is among the assets
            transferred; (d) to third parties for marketing purposes if you have not
            opted out of these disclosures as set forth below; (e) (f) to fulfill the
            purpose for which you provide it (for example, if you give us an email
            address to use an “email a friend” feature of our Websites or mobile
            applications, we will transmit the contents of that email and your email
            address to the recipients); (g) for any other purpose disclosed by us when
            you provide the information; and (h) with your consent.
          </p>
          <p>
            We may also disclose information, including any Personal Information (a) to
            comply with any court order, law, or legal process, including to respond to
            any government or regulatory request; (b) to enforce or apply our Terms of
            Use and other agreements, including for billing and collection purposes;
            and (c) if we believe disclosure is necessary or appropriate to protect the
            rights, property, or safety of our Company, our customers, or others (this
            includes exchanging information with other companies and organizations for
            the purposes of fraud protection and credit risk reduction) to the full
            extent permitted by law.
          </p>
          <h2>
            Choices About How We Use and Disclose Your Information.
          </h2>
          <p>
            You can set your browser to refuse all or some browser cookies, or to alert
            you when cookies are being sent. To learn how you can manage your Flash
            cookie settings, visit the Flash player settings page on Adobe's
            <a
                href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
            >
              website
            </a>
            here:
            <a
                href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
            >
              www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html
            </a>
            . If you disable or refuse cookies, please note that some parts of this
            site may then be inaccessible or not function properly.
          </p>
          <p>
            If you do not wish to have your email address used by the Company to
            promote our own or third parties' products or services, you can opt-out by
            sending us an email stating your request using the contact information at
            the bottom of this Privacy Policy. This opt out does not apply to
            information provided to or by the Company as a result of a product
            purchase, warranty registration, product service experience or other
            transactions.
          </p>
          <p>
            If you do not wish to have your phone number used by the Company to promote
            our own or third parties' products or services, you can opt-out by using
            the opt-out features adopted by the text feature or by sending us an email
            stating your request using the contact information at the bottom of this
            Privacy Policy. This opt out does not apply to information provided to or
            by the Company as a result of a product purchase, warranty registration,
            product service experience or other transactions.
          </p>
          <p>
            We do not control third parties' collection or use of your information to
            serve interest-based advertising. However, these third parties may provide
            you with ways to choose not to have your information collected or used in
            this way. You can opt out of receiving targeted ads from members of the
            Network Advertising Initiative ("NAI") on the NAI's website here:
            <a href="http://optout.networkadvertising.org/?c=1">
              http://optout.networkadvertising.org/?c=1
            </a>
            .
          </p>
          <p>
            California residents may have additional personal information rights and
            choices. Please see Your California Privacy Rights section for more
            information.
          </p>
          <h2>
            Accessing and Correcting Your Personal Information.
          </h2>
          <p>
            You can review and change your Website account information by logging into
            the Websites and visiting your account profile page. You can update your
            Store account information in-Store with one of our sales representatives.
          </p>
          <h2>
            Children Under the Age of 13.
          </h2>
          <p>
            The Websites are not intended for children under 13 years of age and we do
            not knowingly collect Personal Information from children under 13. Further,
            if you are under 18 years of age, do not use or provide any information on
            or through our Websites, make any purchases on or through the Websites, use
            any of the interactive or public comment features of the Websites, or
            provide any information about yourself to us, including your name, address,
            telephone number, photos, videos, email address, any screen name or user
            name you may use, or any other Personal Information, without the express
            consent and participation of a guardian or parent. If we learn we have
            collected or received Personal Information from a child under 13 without
            verification of parental consent, we will delete that information. If you
            believe we might have any information from or about a child under 13,
            please notify us by using the contact information at the bottom of this
            Privacy Policy.
          </p>
          <div dir="ltr">
            <table>
              <colgroup>
                <col width="624"/>
              </colgroup>
              <tbody>
              <tr>
                <td>
                  <p>
                    Your California Privacy Rights
                  </p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <p>
            If you are a California resident, California law may provide you with
            additional rights regarding our use of your personal information. To learn
            more about your California privacy rights, visit our Websites from time to
            time.
          </p>
          <p>
            California's "Shine the Light" law (Civil Code Section § 1798.83) permits
            users of our Websites that are California residents to request certain
            information regarding our disclosure of personal information to third
            parties for their direct marketing purposes. To make such a request, please
            send an email using the contact information on the bottom of this Privacy
            Policy.
          </p>
          <h2>
            Career Information.
          </h2>
          <p>
            We may obtain information from resume or job application materials that you
            submit to us for consideration for employment (a “Job Application”).
            References and background checks may be collected for job applicants.
            Personal Information submitted as part of a Job Application is not
            published online and is shared only with persons involved in the hiring
            process at our Company, one of its affiliates, or at a service provider
            providing services to the Company as part of the hiring process.
          </p>
          <h2>
            Data Security.
          </h2>
          <p>
            We have implemented measures designed to secure your Personal Information
            from accidental loss and from unauthorized access, use, alteration, and
            disclosure. The safety and security of your information also depends on
            you. Where we have given you (or where you have chosen) a password for
            access to certain parts of our Websites or your customer account, you are
            responsible for keeping this password confidential. We urge you to be
            careful about giving out information in public areas of the Websites like
            message boards and feedback posts. Unfortunately, the transmission of
            information via the internet and mobile platforms is not completely secure.
            Although we do our best to protect your Personal Information, we cannot
            guarantee the security of your Personal Information transmitted to or
            through the Websites. Any transmission of Personal Information is at your
            own risk. We are not responsible for circumvention of any privacy settings
            or security measures we provide. You are also responsible for safeguarding
            your personal devices.
          </p>
          <h2>
            Changes to Our Privacy Policy.
          </h2>
          <p>
            It is our policy to post any changes we make to our privacy policy on this
            page. If we make material changes to how we treat our users' Personal
            Information, we will post the new Privacy Policy on this page. The date the
            privacy policy was last revised is identified at the top of the page. You
            are responsible for periodically visiting this privacy policy to check for
            any changes.
          </p>
          <h2>
            Contact Information.
          </h2>
          <p>
            To ask questions or comment about this Privacy Policy and our privacy
            practices, contact us at:
          </p>
          <p>
            Vintage Stock, Inc.
            <br/>
            202 East 32nd Street
            <br/>
            Joplin, MO 64804
            <br/>
            417-623-1550
            <br/>
            <a href="mailto:comments@vintagestock.com">comments@vintagestock.com</a>
          </p>
        </div>
    );
  }
}

export default PrivacyPolicy
