import React, {useEffect} from 'react';
import LoadingCassette from "components/LoadingCassette/index";
import CartItemContainer from "modules/store/scenes/Cart/components/CartItem/container/index";
import CartTotalsContainer from "modules/store/scenes/Cart/components/CartTotals/container/index";
import {HEADER} from "modules/store/scenes/Cart/components/CartTotals/constants/contexts";
import {CART_ITEM_IN_HEADER} from "modules/store/scenes/Cart/components/CartItem/constants/contexts";
import EmptyCart from "modules/store/scenes/Cart/components/EmptyCart/index";
import {getCartSession} from "services/store";
import {MenuItem} from "react-bootstrap";

export default function CartItemList({store, fetchCartItems}) {
    const cartSession = getCartSession();

    useEffect(() => {
        if (cartSession !== null && (!store.loadingCart && !store.cart.id))
            fetchCartItems();
    });

    return (
        <div className="header-cart">
            <div className="header-cart__items">
                {
                    store.loadingCart ? (
                        <LoadingCassette/>
                    ) : (
                        store.cart.cart_items.length > 0 ? (
                            store.cart.cart_items.map(item => (
                                <MenuItem key={`cartItem${item.id}`}>
                                    <CartItemContainer
                                        context={CART_ITEM_IN_HEADER}
                                        item={item}/>
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem>
                                <EmptyCart/>
                            </MenuItem>
                        )
                    )
                }
            </div>
            {
                (store.cart !== null && store.cart.cart_items.length > 0) && (
                    <div className="subtotal">
                        <CartTotalsContainer
                            context={HEADER}/>
                    </div>
                )
            }
        </div>
    )
}