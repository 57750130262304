import React, {PureComponent} from 'react';

class ReturnPolicy extends PureComponent {
  render() {
    return (
        <div className="return-policy">
          <h1>
            Return Policy
          </h1>
          <p>
            Last Modified: June 5, 2019
          </p>
          <h2>
            Acceptance
          </h2>
          <p>
            By purchasing products (the “Purchased Products”) from Vintage Stock Inc.,
            (“VSI”, “we” or “us”), either at a VSI store (each, a “Store”) or online
            through VSI’s websites, including without limitation,<a
              href="http://www.vintagestock.com">www.vintagestock.com</a>, <a
              href="http://www.entertainmart.com">www.entertainmart.com</a>, and
            <a href="http://www.movietradingcompany.com">
              www.movietradingcompany.com
            </a>
            (each, a “Website”) you agree to take the Purchased Products subject to
            this return policy (the “Return Policy”). VSI reserves the right to modify
            the terms of this Return Policy at any time.
          </p>
          <h2>
            Returns
          </h2>
          <p>
            VSI will accept returns of Purchased Products within 30 days after purchase
            (the “Qualifying Period”), subject to the terms of this Return Policy. VSI
            reserves the right to refuse, warn, or limit returns or exchanges
            regardless of whether you have a receipt.
          </p>
          <p>
            All returns must be accompanied by an original receipt, gift receipt, or
            packing slip, but all non-receipt returns are subject to VSI’s approval.
            Store receipts must accompany any item returned due to product defects.
            Each return is subject to applicable laws. Each Store manager has the
            authority to make the final decision for all returns (including whether a
            product is defective).
          </p>
          <h2>
            Qualifying Products
          </h2>
          <p>
            Qualifying defective products will be exchanged for a duplicate item within
            the Qualifying Period. If no duplicate is in stock, either a cash refund or
            a Store credit will be provided or an item substitution (or combination of
            any of the foregoing) will be made. No exchange or refund will be provided
            for Purchased Products due to price changes, dislike of content, or game
            play. Credit card refunds are issued only to the card used for purchase.
            Video game systems must be returned with the original box intact. A valid
            photo ID is required to process all refunds/exchanges.
          </p>
          <h2>
            The following Purchased Products shall not qualify for a return/exchange:
          </h2>
          <ul>
            <li dir="ltr">
              Damaged or abused products
            </li>
            <li dir="ltr">
              Incomplete products
            </li>
            <li dir="ltr">
              Opened non-defective products
            </li>
            <li dir="ltr">
              Comics, cards, books, toys, and new LP sales
            </li>
          </ul>
          <p>
            Please do not mail non-qualifying Purchased Products, as we will not refund
            non-qualifying items and cannot return the items to you.
          </p>
          <h2>
            Video Game Systems
          </h2>
          <p>
            To the extent a manufacturer’s warranty has been provided for the Purchased
            Product, the terms and conditions of a Purchased Product’s manufacturer’s
            warranty shall apply to defective video game systems after the Qualifying
            Period.
          </p>
          <h2>
            New Purchased Products
          </h2>
          <p>
            All new Purchased Products must be in the original unopened packaging in
            sellable condition at the time of return/exchange.
          </p>
          <h2>
            Disclaimer
          </h2>
          <p>
            EXCEPT AS SET FORTH IN THIS RETURN POLICY, VSI DISCLAIMS ALL WARRANTIES OF
            ANY KIND WITH RESPECT TO PURCHASED PRODUCTS, WHETHER EXPRESS OR IMPLIED,
            STATUTORY OR OTHERWISE, INCLUDING ANY (a) WARRANTY OF MERCHANTABILITY; (b)
            WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE; (c) WARRANTY OF TITLE; OR (d)
            WARRANTY AGAINST INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD
            PARTY; WHETHER EXPRESS OR IMPLIED BY LAW, COURSE OF DEALING, COURSE OF
            PERFORMANCE, USAGE OF TRADE, OR OTHERWISE. THE FOREGOING DOES NOT AFFECT
            ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW. TO
            THE FULL EXTENT PERMITTED BY LAW, UNDER NO CIRCUMSTANCES SHALL VSI’S
            AGGREGATE LIABILITY TO YOU FOR ANY PURCHASED PRODUCT EXCEED YOUR PURCHASE
            PRICE FOR THE PURCHASED PRODUCT.
          </p>
          <h2>
            Returns by Mail
          </h2>
          <p>
            Contact us at <a href="mailto:orders@vintagestock.com">orders@vintagestock.com</a> for
            authorization before returning any Purchased Product via mail. If approved,
            pack your return in the original shipping package, include the packing slip
            and return label, and indicate the reason for the return. For proof of
            delivery, we recommend that you return items via UPS or insured USPS.
            Mailed returns should be shipped to:
          </p>
          <p>
            Vintage Stock, Inc.
            <br/>
            101 North Range Line Road, Suite 118
            <br/>
            Joplin, Missouri 64801
          </p>
          <p>
            Shipping costs are non-refundable. If you believe the reason for your
            return is due to our error, please contact us as <a
              href="mailto:orders@vintagestock.com">orders@vintagestock.com</a> for
            resolution.
          </p>
          <h2>
            In Store Returns
          </h2>
          <p>
            Qualifying returns may be returned to any physical Store location. In-Store
            returns require legal identification and are subject to the terms of this
            Return Policy.
          </p>
          <div>
          </div>
        </div>
    );
  }
}

export default ReturnPolicy
