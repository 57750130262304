import React, {useCallback, useEffect, useState} from 'react';
import className from 'classnames/bind';
import vintageAxios from "services/api/index";
import LoadingCassette from "components/LoadingCassette";
import {
  QUERY_PARAM_COMING_SOON,
  QUERY_PARAM_NEW_THIS_WEEK,
  VIEW_TYPE
} from "modules/store/scenes/ProductsList/constants/query-params";
import Product from "modules/store/scenes/ProductsList/components/Product";
import "./styles/HomeFeaturedProducts.scss"

export default function HomeFeaturedProducts() {
  const [products, setProducts] = useState(null)
  const [tabs, setTabs] = useState([
    // {
    //   name: 'Most Viewed',
    //   active: true,
    //   [VIEW_TYPE]: QUERY_PARAM_MOST_VIEWED
    // },
    // {
    //   name: 'Coming soon',
    //   active: true,
    //   [VIEW_TYPE]: QUERY_PARAM_COMING_SOON
    // },
    {
      name: 'New Releases',
      active: true,
      [VIEW_TYPE]: QUERY_PARAM_NEW_THIS_WEEK
    },
  ])

  const fetchFeaturedProducts = useCallback(() => {
    const activeTabs = tabs.filter(tab => tab.active);
    const promise = vintageAxios.get('/products/all/', {
      params: {
        page_size: 8,
        [VIEW_TYPE]: activeTabs[0][VIEW_TYPE]
      },
    });
    return promise.then(response => {
      setProducts(response.data.results)
    });
  }, [tabs])

  useEffect(() => {
    if (!products)
      fetchFeaturedProducts()
  }, [products, fetchFeaturedProducts])

  function handleTabClick(event) {
    event.preventDefault()
    setTabs(tabs.map(tab => ({
      ...tab,
      active: event.target.dataset.name === tab.name
    })))
    setProducts(null)
  }

  return (
      <section className="featured-products__container">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="nav nav-tabs featured-products__tabs" role="tablist">
                {
                  tabs.map((tab, index) => (
                      <li
                          key={`homeFeaturedProductsTab${index}`}
                          role="tab"
                          className={className({
                            "featured-products__tab col-xs-6 col-sm-6 col-md-6 col-lg-6": true,
                            "active": tab.active
                          })}>
                        <a
                            onClick={handleTabClick}
                            data-toggle="tab"
                            data-name={tab.name}
                            className="text-uppercase" id={`tab${index}`}>
                          {tab.name}
                        </a>
                      </li>
                  ))
                }
              </ul>
              <div className="featured-products__tabs-content">
                <div className="tab-pane active">
                  <div className="products-grid">
                    {
                      !products ? (
                          <LoadingCassette/>
                      ) : (
                          products.map(product => (
                              <div
                                  key={product.id}
                                  className="products-grid__product">
                                <Product product={product}/>
                              </div>
                          ))
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}