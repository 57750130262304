import React, {Component} from 'react';
import {updateQueryParams} from "services/browser-history/index";
import debounce from "lodash/debounce";
import queryString from "query-string";
import {withRouter} from "react-router-dom";

class OrderHistoryFilters extends Component {
  constructor(props){
    super(props);

    this.state = {
      search: this.parsedParams['search'] || '',
      year: this.parsedParams['year'] || '',
      ordering: this.parsedParams['ordering'] || ''
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleYearSelectChange = this.handleYearSelectChange.bind(this);
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.handleOrderBySelectChange = this.handleOrderBySelectChange.bind(this);
  }

  /**
   * Get parsed version of the query params
   */
  get parsedParams(){
    return queryString.parse(this.props.location.search);
  }


  /**
   * Generate a list with the last 10 years from now
   * @returns {Array} years
   */
  get years(){
    const currentYear = new Date().getFullYear();
    const array = [currentYear];
    let counter = 1;
    while( counter <= 10 ){
      array.push(currentYear - counter);
      counter += 1;
    }
    return array;
  }

  /**
   * Prevent default behaviour on form submit.
   * @param {Object} event
   */
  handleFormSubmit(event){
    event.preventDefault();
    this.fetchOrders();
  }

  /**
   * Handle changes in the year <select>
   * @param {Object} event
   */
  handleYearSelectChange(event){
    const value = event.target.value;
    this.setState({year: value});
    updateQueryParams({
      year: value
    });
  }

  /**
   * Update query params with the search query.
   * This is in a separate function to be able to be debounced.
   */
  updateSearchInQueryParams(){
    updateQueryParams({
      search: this.state.search
    });
  }

  debounceUpdateSearchInQueryParams = debounce(this.updateSearchInQueryParams, 500);

  /**
   * Handle changes on the search input
   * @param {Object} event
   */
  handleSearchInputChange(event){
    const value = event.target.value;
    this.setState({
      search: value
    }, () => {
      this.debounceUpdateSearchInQueryParams();
    });
  }

  /**
   * Handle changes on the order by select
   * @param {Object} event
   */
  handleOrderBySelectChange(event){
    const value = event.target.value;
    this.setState({ordering: value});
    updateQueryParams({
      ordering: value
    });
  }

  render(){
    const {search, year, ordering} = this.state;
    const orderingFilters = [
      {
        name: 'ID (Asc)',
        param: 'uuid'
      },
      {
        name: 'ID (Desc)',
        param: '-uuid'
      },
      {
        name: 'Date (Asc)',
        param: 'created_at'
      },
      {
        name: 'Date (Desc)',
        param: '-created_at'
      },
      {
        name: 'Total (Asc)',
        param: 'total'
      },
      {
        name: 'Total (Desc)',
        param: '-total'
      }
    ];

    return(
        <div>
          <form
              onSubmit={this.handleFormSubmit}>
            <div className="row">
              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="year">Year</label>
                  <select
                      id="year"
                      className="form-control"
                      value={year}
                      onChange={this.handleYearSelectChange}>
                    <option value="">All Orders</option>
                    {
                      this.years.map(year =>
                          <option
                              key={`year${year}`}
                              value={year}>
                            {year}
                          </option>
                      )
                    }
                  </select>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="search">Filter orders</label>
                  <input
                      type="text"
                      id="search"
                      className="form-control"
                      value={search}
                      placeholder="Type order id or product name..."
                      onChange={this.handleSearchInputChange}/>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="order-by">Order by</label>
                  <select
                      id="order-by"
                      className="form-control"
                      value={ordering}
                      onChange={this.handleOrderBySelectChange}>
                    {
                      orderingFilters.map((filter, index) =>
                          <option
                              key={`orderByFilter${index}`}
                              value={filter.param}>
                            {filter.name}
                          </option>
                      )
                    }
                  </select>
                </div>
              </div>
            </div>
          </form>
        </div>
    )
  }
}

export default withRouter(OrderHistoryFilters)