import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {renderInput, renderSelect} from "utils/forms/renderers";
import {number} from "utils/forms/validators";
import {
  PRICE_GTE,
  PRICE_LTE, QUERY_PARAM_CAST,
  QUERY_PARAM_CATEGORIES,
  QUERY_PARAM_DESCRIPTION,
  QUERY_PARAM_ESRB_RATING,
  QUERY_PARAM_FORMAT,
  QUERY_PARAM_GAMING_PLATFORM,
  QUERY_PARAM_GENRES,
  QUERY_PARAM_MEDIA_TYPE,
  QUERY_PARAM_MPAA_RATING,
  QUERY_PARAM_NAME
} from "modules/store/scenes/ProductsList/constants/query-params";
import vintageAxios from "services/api/index";
import {GAME, MOVIE} from "modules/store/scenes/AdvancedSearch/constants/constants";
import {CATEGORIES_ID_SHOW} from "modules/store/scenes/ProductsList/components/ProductsListSidebarV2/constants/constants";
import "./styles/AdvancedSearch.scss";

export class AdvancedSearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moviesGenres: [],
      gamesGenres: [],
      categories: [],
      mediaType: [],
      platforms: []
    };

    this.validateSelectedRating = this.validateSelectedRating.bind(this);
    this.resetInitialValues = this.resetInitialValues.bind(this);
  }

  componentDidMount() {
    this.fetchGenres();
    this.fetchCategories();
    this.fetchPlatforms();
  }

  /**
   * Fetch genres from the API.
   * @returns {AxiosPromise}
   */
  fetchGenres() {
    const promise = vintageAxios.get('/products/genres/');
    promise.then(response => {
      const genres = response.data.results;
      this.setState({
        moviesGenres: genres.filter(genre => genre.types.includes(MOVIE)),
        gamesGenres: genres.filter(genre => genre.types.includes(GAME))
      });
    });
    return promise;
  }

  /**
   * Fetch categories from the API.
   * @returns {AxiosPromise}
   */
  fetchCategories() {
    const promise = vintageAxios.get('/products/categories/');
    promise.then(response => {
      const allCategories = response.data;
      /*Categories list to show*/
      const categories = allCategories.filter(item => {
        return CATEGORIES_ID_SHOW.find(
            category => category === item.id
        ) ? item : undefined;
      }).map(category => {
        /*Get subcategories*/
        category["subcategories"] = allCategories.filter(_category => _category.parent === category.id);
        return category;
      });

      this.setState({
        categories: categories,
        mediaType: AdvancedSearchForm.mediaTypeToShowFromCategories(allCategories)
      });
    });
    return promise;
  }

  /**
   * Fetch platforms from the API.
   * @returns {AxiosPromise}
   */
  fetchPlatforms() {
    const promise = vintageAxios.get('/products/platforms/');
    promise.then(response => {
      this.setState({
        platforms: response.data.results
      })
    });
    return promise;
  }

  /**
   * List of media type to show from categories
   * @param categories
   */
  static mediaTypeToShowFromCategories(categories) {
    const mediaTypeToShow = ["DVD", "Blu-ray", "Games"];
    return categories.filter(item => {
      return mediaTypeToShow.find(
          category => category === item.name
      ) ? item : undefined;
    })
  }

  /**
   * Verify if selected other rating field when selected already
   * @param event
   */
  validateSelectedRating(event) {
    this.props.changeFieldValue(event.target.name === QUERY_PARAM_ESRB_RATING
        ? QUERY_PARAM_MPAA_RATING
        : QUERY_PARAM_ESRB_RATING, []);
  }

  resetInitialValues() {
    this.props.reset();
    this.props.resetInitialValues();
  }

  render() {
    const {moviesGenres, gamesGenres, categories, mediaType, platforms} = this.state;
    const {handleSubmit} = this.props;

    return (
        <form
            id="advanced-search-form"
            className="form"
            onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <div className="form-group">
                <label htmlFor={"search_name"}>Name</label>
                <Field
                    id={QUERY_PARAM_NAME}
                    name={QUERY_PARAM_NAME}
                    component={renderInput}
                    type="text"
                    className="form-control"
                    placeholder='Type product name'/>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="form-group">
                <label htmlFor={QUERY_PARAM_DESCRIPTION}>Short Description</label>
                <Field id={QUERY_PARAM_DESCRIPTION}
                       name={QUERY_PARAM_DESCRIPTION}
                       component={renderInput}
                       type="text"
                       autoComplete="description-product"
                       className="form-control"
                       placeholder="Type short description"/>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="form-group">
                <label htmlFor="SKU">SKU</label>
                <Field id="sku"
                       name="sku"
                       component={renderInput}
                       type="text"
                       className="form-control"
                       placeholder="Type product SKU"/>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="price">Price</label>
                    <Field id={PRICE_GTE}
                           name={PRICE_GTE}
                           component={renderInput}
                           type="number"
                           className="form-control"
                           validate={[number]}
                           placeholder="Min"/>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="">&nbsp;</label>
                    <br/>
                    <Field id={PRICE_LTE}
                           name={PRICE_LTE}
                           component={renderInput}
                           type="number"
                           className="form-control"
                           validate={[number]}
                           placeholder="Max"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <div className="form-group">
                <label htmlFor={QUERY_PARAM_CAST}>Artist</label>
                <Field id={QUERY_PARAM_CAST}
                       name={QUERY_PARAM_CAST}
                       component={renderInput}
                       type="text"
                       className="form-control"
                       placeholder='Type an artist name'/>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="form-group">
                <label htmlFor={QUERY_PARAM_GENRES}>Genre</label>
                <Field name={QUERY_PARAM_GENRES}
                       component={renderSelect}
                       className="form-control">
                  <optgroup label="All">
                    <option value=''>All</option>
                  </optgroup>
                  <optgroup label="Movies">
                    {
                      moviesGenres.map(movieGenre => {
                        return <option
                            key={`movie_genre${movieGenre.id}`}
                            value={movieGenre.id}>
                          {movieGenre.name}
                        </option>
                      })
                    }
                  </optgroup>
                  <optgroup label="Gaming">
                    {
                      gamesGenres.map(gameGenre => {
                        return <option
                            key={`movie_genre${gameGenre.id}`}
                            value={gameGenre.id}>
                          {gameGenre.name}
                        </option>
                      })
                    }
                  </optgroup>
                </Field>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="form-group">
                <label htmlFor={QUERY_PARAM_MEDIA_TYPE}>Media Type</label>
                <Field multiple={true} type="select-multiple"
                       name={QUERY_PARAM_MEDIA_TYPE}
                       component={renderSelect}
                       className="form-control">
                  {
                    mediaType.map(item => (
                        <option
                            key={`media_type${item.id}`}
                            value={item.id}>{item.name}</option>
                    ))
                  }
                  <option value="" disabled={true}>&nbsp;</option>
                </Field>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="form-group">
                <label htmlFor={QUERY_PARAM_GAMING_PLATFORM}>Platform</label>
                <Field multiple={true} type="select-multiple"
                       name={QUERY_PARAM_GAMING_PLATFORM}
                       component={renderSelect}
                       className="form-control select-platform">
                  {
                    platforms.map((platform, index) => (
                        <option
                            key={`${QUERY_PARAM_GAMING_PLATFORM}_${index}`}
                            value={platform.id}>{platform.name}</option>
                    ))
                  }
                </Field>
              </div>
            </div>
          </div>
          <hr/>
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <div className="form-group">
                <label htmlFor={QUERY_PARAM_ESRB_RATING}>ESRB Rating</label>
                <Field
                    multiple={true}
                    type="select-multiple"
                    name={QUERY_PARAM_ESRB_RATING}
                    component={renderSelect}
                    className="form-control"
                    onChange={this.validateSelectedRating}>
                  <option value="n_a">N/A</option>
                  <option value="e">E</option>
                  <option value="t">T</option>
                  <option value="m">M</option>
                </Field>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="form-group">
                <label htmlFor={QUERY_PARAM_MPAA_RATING}>MPAA Rating</label>
                <Field multiple={true} type="select-multiple"
                       name={QUERY_PARAM_MPAA_RATING}
                       component={renderSelect}
                       className="form-control"
                       onChange={this.validateSelectedRating}>
                  <option value="n_a" defaultValue={true}>N/A</option>
                  <option value="e">E</option>
                  <option value="t">T</option>
                  <option value="b">M</option>
                </Field>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="form-group">
                <label htmlFor={QUERY_PARAM_FORMAT}>Format</label>
                <Field multiple={true} type="select-multiple"
                       name={QUERY_PARAM_FORMAT}
                       component={renderSelect}
                       className="form-control">
                  <option value="0">Widescreen</option>
                  <option value="1">Fullscreen</option>
                  <option value="2">Special Edition</option>
                  <option value="" disabled={true}>&nbsp;</option>
                </Field>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="form-group">
                <label htmlFor={QUERY_PARAM_CATEGORIES}>Categories</label>
                <Field name={QUERY_PARAM_CATEGORIES}
                       component={renderSelect}
                       className="form-control">
                  <optgroup label="All">
                    <option value=''>All</option>
                  </optgroup>
                  {
                    categories.length > 0 ? categories.map(category => {
                      if (category.subcategories.length > 0) {
                        return <optgroup label={category.name}
                                         key={`category${category.name}`}>
                          {
                            category.subcategories.map(subcategory => {
                              return <option
                                key={`subcategory${subcategory.id}`}
                                value={subcategory.id}>
                                {subcategory.name}
                              </option>
                            })
                          }
                        </optgroup>
                      } else {
                        return <option 
                          value={category.id} 
                          key={`category${category.name}`}>
                          {category.name}
                        </option>;
                      }
                    }):null
                  }
                </Field>
              </div>
            </div>
          </div>
          <hr/>
          <div className="align-right">
            <div className="form-group">
              <button
                  type="button"
                  onClick={this.resetInitialValues}
                  className="btn-custom btn-custom--gray">RESET
              </button>
              <button
                type="submit"
                className={`btn-custom ${this.props.pristine || this.props.invalid ? 'btn-disabled' : ''}`}
                disabled={this.props.pristine || this.props.invalid}>
                <span>SEARCH</span>
              </button>
            </div>
          </div>
        </form>
    )
  }
}

const AdvancedSearchReduxForm = reduxForm({
  form: 'vintageAdvancedSearchForm',
  enableReinitialize: true
})(AdvancedSearchForm);

export default AdvancedSearchReduxForm;
