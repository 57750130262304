import React, {useContext} from "react";
import "./styles/PriceTable.scss"
import {ProductContext} from "@vintagestock/vintage-common-components"
import {
  RADIO_OFFLINE_NEW,
  RADIO_OFFLINE_USED,
  RADIO_ONLINE_NEW,
  RADIO_ONLINE_USED
} from "modules/store/components/PriceTable/constants/radios"
import PriceLabel from "modules/store/components/PriceTable/components/PriceLabel"

function Radio(props) {
  const {product} = useContext(ProductContext)

  const location = [RADIO_ONLINE_NEW, RADIO_ONLINE_USED].includes(props.value) ? "in store" : "online";
  const status = (props.value === RADIO_ONLINE_NEW || props.value === RADIO_OFFLINE_NEW) ? "New" : "Used";

  return (
      <input
          type="radio"
          name={`price-radio-${product.id}`}
          aria-label={`Select ${product.name} ${status} ${location}`}
          {...props}/>
  )
}

export default function PriceTable() {
  const {product, activeRadio, setActiveRadio} = useContext(ProductContext)

  function handleChange(event) {
    setActiveRadio(event.target.value)
  }

  return (
      <table className="table price-table">
        <thead>
        <tr>
          <td/>
          <td>
            <PriceLabel
                label="USED"
                hasStock={product.total_stock_used > 0 || product.pickup_available_used}
                price={product.price_used}
                color="blue"/>
          </td>
          <td>
            <PriceLabel
                label="NEW"
                hasStock={product.is_pre_order || product.total_stock_new > 0 || product.pickup_available_new}
                price={product.price_new}
                color="blue"/>
          </td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>ONLINE</td>
          <td>
            <Radio
                value={RADIO_ONLINE_USED}
                checked={activeRadio === RADIO_ONLINE_USED}
                disabled={product.total_stock_used === 0}
                onChange={handleChange}/>
          </td>
          <td>
            <Radio
                value={RADIO_ONLINE_NEW}
                checked={activeRadio === RADIO_ONLINE_NEW}
                disabled={!product.is_pre_order && product.total_stock_new === 0}
                onChange={handleChange}/>
          </td>
        </tr>
        <tr>
          <td>IN STORE</td>
          <td>
            <Radio
                value={RADIO_OFFLINE_USED}
                checked={activeRadio === RADIO_OFFLINE_USED}
                disabled={product.pickup_available_used === false}
                onChange={handleChange}/>
          </td>
          <td>
            <Radio
                value={RADIO_OFFLINE_NEW}
                checked={activeRadio === RADIO_OFFLINE_NEW}
                disabled={product.pickup_available_new === false}
                onChange={handleChange}/>
          </td>
        </tr>
        </tbody>
      </table>
  )
}