import React, {Component} from 'react';
import {FROM} from "modules/store/scenes/ProductsList/constants/query-params";
import {DropdownButton, MenuItem} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {apiGet} from "services/api";
import {saveUser} from "services/auth";

import "./styles/HeaderUserDropdown.scss";

class HeaderUserDropdown extends Component {
  componentDidMount() {
    if (this.props.auth.user === null && this.props.isAuthenticated === false) {
      this.fetchUser();
    }
  }

  fetchUser() {
    let promise = apiGet({resourceName: 'customer'});
    promise.then(response => {
      const {user} = response.data;
      this.props.receiveUser(user);
      saveUser(user);
    });
  }

  /**
   * Extract first word of a text.
   * @param {String} text
   * @return {String} word
   */
  static firstWord(text) {
    return text ? text.split(" ")[0] : text;
  }

  render () {
    const {auth, location} = this.props;

    if (auth.isAuthenticated)
      return (
        <DropdownButton
          id="HeaderAccountDropdown"
          className="header-account-dropdown-button"
          title={
            <span className="icon-user">
              <span className="header-account-dropdown-button__text">
                {auth.user === null ? 'MY ACCOUNT' : HeaderUserDropdown.firstWord(auth.user.first_name).toUpperCase()}
              </span>
            </span>
          }>
          <MenuItem componentClass="div" className="header-account-dropdown-item">
            <Link
              to="/my-account/profile"
              className="header-account-dropdown-item__link">
              <span className="icon icon-user"/>
              <span className="link-display-text">My Account</span>
            </Link>
          </MenuItem>
          <MenuItem componentClass="div" className="header-account-dropdown-item">
            <Link to="/my-account/wishlist"
              className="header-account-dropdown-item__link">
              <span className="icon icon-wishlist-book"/>
              <span className="link-display-text">Wish List</span>
            </Link>
          </MenuItem>
          <MenuItem componentClass="div" className="header-account-dropdown-item">
            <Link to="/auth/logout"
              className="header-account-dropdown-item__link">
              <span className="icon icon-logout"/>
              <span className="link-display-text">Sign Out</span>
            </Link>
          </MenuItem>
        </DropdownButton>
      );
    else
      return (
        <div className="header-account-dropdown-item">
          <Link to={{
            pathname: '/auth/login',
            search: `?${FROM}=${location.pathname}`
          }}
          className="header-account-dropdown-item__link">
            <span className="icon icon-user"/>
            <span className="header-account-dropdown-button__text">MY ACCOUNT</span>
          </Link>
        </div>
      );
  }
}

export default withRouter(HeaderUserDropdown);
