import React from 'react';
import NumberFormat from "react-number-format";

export const renderInput = ({ input, id, type, autoFocus, placeholder, autoComplete, className, meta: { touched, error, warning } }) => (
    <div className="form-field">
      <input id={id} autoFocus={autoFocus} placeholder={placeholder} {...input} type={type} className={className}
             autoComplete={autoComplete || input.name}/>
      {
        touched && (
            (error && <span className="help-block">{error}</span>)
            ||
            (warning && <span className="help-block">{warning}</span>)
        )
      }
    </div>
);

export const renderSelect = ({ input, id, multiple, className, children, meta: { touched, error, warning } }) => (
    <div>
      <select
          multiple={multiple}
          id={id ? id : input.name}
          {...input}
          className={className}>
        {children}
      </select>
      {
        touched && (
            (error && <span className="help-block">{error}</span>)
            ||
            (warning && <span className="help-block">{warning}</span>)
        )
      }
    </div>
);

export const renderCheckbox = ({ input, id, disabled, readOnly, className, checked, defaultChecked, meta: { touched, error, warning } }) => (
    <div className="form-field">
      <input
          id={id}
          disabled={disabled}
          readOnly={readOnly}
          type="checkbox"
          defaultChecked={defaultChecked}
          checked={checked}
          className={className}
          {...input}/>
      {
        touched && (
            (error && <span className="help-block">{error}</span>)
            ||
            (warning && <span className="help-block">{warning}</span>)
        )
      }
    </div>
);

export const renderRadio = ({ input, id, checked, disabled, readOnly, className, defaultChecked, meta: { touched, error, warning } }) => (
    <div className="form-field">
      <input
          id={id}
          disabled={disabled}
          readOnly={readOnly}
          type="radio"
          checked={checked}
          defaultChecked={defaultChecked}
          className={className}
          {...input}/>
      {
        touched && (
            (error && <span className="help-block">{error}</span>)
            ||
            (warning && <span className="help-block">{warning}</span>)
        )
      }
    </div>
);

export const renderTextarea = ({ input, className, meta: { touched, error, warning } }) => (
    <div className="form-field">
      <textarea  {...input} id={input.name} autoComplete={input.name} className={className}/>
      {
        touched && (
            (error && <span className="help-block">{error}</span>)
            ||
            (warning && <span className="help-block">{warning}</span>)
        )
      }
    </div>
);

export const renderPhoneInput = ({ input, id, autoFocus, placeholder, autoComplete, className, meta: { touched, error, warning } }) => (
    <div className="form-field">
      <NumberFormat
          id={id}
          format="(###) ###-####"
          displayType="input"
          className={className || "form-control"}
          placeholder={placeholder}
          type="tel"
          {...input}
      />
      {
        touched && (
            (error && <span className="help-block">{error}</span>)
            ||
            (warning && <span className="help-block">{warning}</span>)
        )
      }
    </div>
);

export const renderCreditCardInput = ({ input, id, autoFocus, placeholder, autoComplete, className, meta: { touched, error, warning}}) => (
    <div className="form-field">
      <NumberFormat
          id={id}
          format="#### #### #### ####"
          displayType="input"
          className={className || "form-control"}
          placeholder={placeholder}
          type="tel"
          {...input}
      />
      {
        touched && (
            (error && <span className="help-block">{error}</span>)
            ||
            (warning && <span className="help-block">{warning}</span>)
        )
      }
    </div>
);