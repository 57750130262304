import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import {renderInput} from "utils/forms/renderers";
import {email, onlyText, required} from "utils/forms/validators";

export class MyProfileForm extends Component {
  render(){
    const {errors, isLoading, handleSubmit} = this.props;

    return(
        <form className="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="first_name">First Name <span className="required">*</span></label>
            <Field id="first_name"
                   name="first_name"
                   component={renderInput}
                   autoComplete={"given-name"}
                   type="text"
                   className="form-control"
                   validate={[required, onlyText]}
                   placeholder="Type your first name"/>
          </div>
          <div className="form-group">
            <label htmlFor="last_name">Last Name <span className="required">*</span></label>
            <Field id="last_name"
                   name="last_name"
                   component={renderInput}
                   autoComplete={"family-name"}
                   type="text"
                   className="form-control"
                   validate={[required, onlyText]}
                   placeholder="Type your last name"/>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email <span className="required">*</span></label>
            <Field id="email"
                   name="email"
                   component={renderInput}
                   type="email"
                   className="form-control"
                   validate={[required, email]}
                   placeholder="Type your email address"/>
            {errors.email && errors.email.map((msj, index) => (
                <span className="help-block" key={`myProfileFormEmailError${index}`}>{msj}</span>
            ))}
          </div>
          {/*<div className="custom-checkbox">
            <input type="checkbox" value="1" checked id="news" name=""/>
            <label htmlFor="news"/>
            <span className="text">Subscribe to our newsletter </span>
          </div>*/}
          <div className="bottom">
            <div className="form-group">
              {
                isLoading ? (
                    <input type="submit" value="SAVING..." disabled={true}/>
                ) : (
                    <input type="submit" value="SAVE CHANGES"/>
                )
              }
            </div>
          </div>
        </form>
    )
  }
}

MyProfileForm = reduxForm({
  form: 'authMyProfileForm'
})(MyProfileForm);

export default MyProfileForm