import React, {Component} from 'react';
import LoginForm from "./components/LoginForm";
import {Redirect} from "react-router";
import {AUTH_LOGIN_SUCCESS} from "modules/auth/actions/types";
import {updateCartOwner} from "services/store";
import {getCartSession} from "services/store";
import vintageAxios from "services/api/index";
import {saveWishList} from "services/store/wishlist";
import queryString from 'query-string';
import {Link} from "react-router-dom";
import SEO from "components/SEO/index";
import AuthSocialButtons from "modules/auth/scenes/Login/components/AuthSocialButtons"

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cartSession: getCartSession()
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleAfterSocialSubmit = this.handleAfterSocialSubmit.bind(this);
  }

  /**
   * If the user created a cart when anon and is logging in, that cart should be merged
   * with the actual user's cart.
   * @returns {AxiosPromise} promise
   */
  convertCart() {
    const promise = updateCartOwner(this.state.cartSession);
    promise.then(response => {
      this.props.fetchCartItemsSuccess(response.data)
    });
    return promise;
  }

  /**
   * Login the user with submitted form data.
   * @param {Object} data
   */
  handleFormSubmit(data) {
    const promise = this.props.onSubmit(data);
    promise.then(action => {
      if (action && action.type === AUTH_LOGIN_SUCCESS) {
        // Save wishlist id if user already has a wishlist
        const promise = vintageAxios.get('/management/wish-lists/');
        promise.then(response => {
          if (response.data.id)
            saveWishList(response.data.id)
        });

        // Convert cart or get cart items
        if (this.state.cartSession)
          this.convertCart();
        else
          this.props.fetchCartItems();
      }
    });
  }

  handleAfterSocialSubmit(response) {
    // Save wishlist id if user already has a wishlist
    const promise = vintageAxios.get('/management/wish-lists/');
    promise.then(response => {
      if (response.data.id)
        saveWishList(response.data.id)
    });

    // Convert cart or get cart items
    if (this.state.cartSession)
      this.convertCart();
    else
      this.props.fetchCartItems();

    this.props.loginSuccess({}, response);
  }

  /**
   * Parses and returns query parameters as an object.
   * @returns {Object} queryParams
   */
  get parsedParams() {
    return queryString.parse(this.props.location.search);
  }

  render() {
    const {from} = this.parsedParams;
    const redirectUrl = from ? from : '/my-account/profile';

    if (this.props.auth.isAuthenticated) {
      return (
          <Redirect to={redirectUrl}/>
      );
    }

    return (
        <div className="login">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="login-wrapper">
                  <div className="lightbox">
                    <div className="brand">
                      <Link to="/"><span className="logo" aria-label="Vintage stock logo"/></Link>
                    </div>
                    <div className="wrapper">
                      <div className="row">
                        <div className="col-md-6">
                          <span className="login-bg"/>
                        </div>
                        <div className="col-md-6">
                          <h1>Log in to your Account</h1>
                          <AuthSocialButtons
                              onAfterSubmit={this.handleAfterSocialSubmit}/>
                          <br/>
                          {
                            this.parsedParams['wishlist'] ? (
                                <p className="login-message login-message--wishlist">
                                  In order to add products into your wish list you must be authenticated.
                                  If you don't have an account please <Link to="/auth/signup">create yours here</Link>.
                                </p>
                            ) : (
                                <p className="login-message login-message--regular">
                                  Please enter your email address and password below to log in.
                                </p>
                            )
                          }
                          <LoginForm
                              onSubmit={this.handleFormSubmit}
                              isLoading={this.props.auth.loginPage.loading}
                              errors={this.props.auth.loginPage.errors}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SEO url='auth/login'/>
        </div>
    )
  }
}

export default Login;
