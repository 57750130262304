import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {renderInput} from "utils/forms/renderers";
import {email, required} from "utils/forms/validators";
import PropTypes from "prop-types";
import "./styles/WishlistShareModalForm.scss";
// import {
//   FacebookIcon,
//   TwitterIcon,
//   FacebookShareButton,
//   TwitterShareButton,
// } from 'react-share';
import {getWishListId} from "services/store/wishlist/index";
import {INITIAL_STATUS, LOADING_STATUS, SUCCESSFUL_STATUS} from "assets/constants/constants";

export class WishlistShareModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wishlist: null,
      urlToShare: typeof window !== 'undefined'
          ? `${window.location.origin}/wishlist/${getWishListId()}`
          : null
    };
  }

  componentDidMount() {
    // this.fetchWishlist();
  }

  /**
   * Fetch wishlist from API by id on localStorage
   * @returns {AxiosPromise<any>}
   */
  // fetchWishlist() {
  //   const promise = vintageAxios.get(`/management/wish-list/${getWishListId()}`);
  //
  //   promise.then(response => {
  //     this.setState({
  //       wishlist: response.data,
  //       urlToShare: typeof window !== 'undefined'
  //           ? `${window.location.origin}/wishlist/${response.data.uuid}`
  //           : null
  //     });
  //   });
  //
  //   return promise;
  // }

  render() {
    const {status, handleSubmit, onClose} = this.props;

    return (
        <form className="wishlist-share-modal-form form" onSubmit={handleSubmit}>
          {
            status === SUCCESSFUL_STATUS ? (
                <p>Your wishlist was successfully shared, thanks.</p>
            ) : (
                <div className="form-group">
                  <label htmlFor="email">Email to:</label>
                  <Field id="email"
                         name="email"
                         component={renderInput}
                         type="email"
                         className="form-control"
                         validate={[required, email]}
                         placeholder="Type your email address"/>
                </div>
            )
          }
          <div className="form-group">
            {/*<div className="wishlist-share-modal-form-social">*/}
              {/*<label>Or Share on:</label>*/}
              {/*<div className="wishlist-share-modal-form-social__buttons">*/}
                {/*<TwitterShareButton*/}
                    {/*url={urlToShare}*/}
                    {/*quote={urlToShare}*/}
                    {/*onShareWindowClose={onClose}>*/}
                  {/*<TwitterIcon*/}
                      {/*size={26}*/}
                      {/*round={true}/>*/}
                {/*</TwitterShareButton>*/}
                {/*<FacebookShareButton*/}
                    {/*url={urlToShare}*/}
                    {/*quote={urlToShare}*/}
                    {/*onShareWindowClose={onClose}>*/}
                  {/*<FacebookIcon*/}
                      {/*size={26}*/}
                      {/*round={true}/>*/}
                {/*</FacebookShareButton>*/}
              {/*</div>*/}
            {/*</div>*/}
            <div className="wishlist-share-modal-form-buttons">
              <button
                  type="button"
                  className="cancel"
                  onClick={onClose}>
                {
                  status !== SUCCESSFUL_STATUS
                      ?
                      <span>CANCEL</span>
                      :
                      <span>CLOSE</span>
                }
              </button>
              {
                status === LOADING_STATUS ? (
                    <input type="submit" value="SHARING..." disabled={true}/>
                ) : (
                    status === INITIAL_STATUS &&
                    <input type="submit" value="SHARE VIA EMAIL"/>
                )
              }
            </div>
          </div>
        </form>
    )
  }
}

WishlistShareModalForm.propTypes = {
  status: PropTypes.oneOf(
      [INITIAL_STATUS, LOADING_STATUS, SUCCESSFUL_STATUS]
  ).isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
  form: 'wishlistShareForm'
})(WishlistShareModalForm);
