import React, {useEffect} from "react";
import {Panel, PanelGroup} from "react-bootstrap";
import {Field, Form, Formik} from "formik";
import {useHistory, useLocation} from "react-router-dom";
import QueryParams from "utils/QueryParams";
import {
  PRICE_NEW_GTE,
  PRICE_NEW_LTE,
  PRICE_USED_GTE,
  PRICE_USED_LTE,
  QUERY_PARAM_PRODUCT_CONDITION
} from "modules/store/scenes/ProductsList/constants/query-params";
import { conditions } from "@vintagestock/vintage-common-components";
import {parseCondition} from "utils/products"
import "./styles/SidebarPrice.scss"

export default function SidebarPrice() {
  const history = useHistory()
  const location = useLocation()
  const queryParams = new QueryParams(location.search)
  const condition = parseCondition(queryParams.find(QUERY_PARAM_PRODUCT_CONDITION));

  const initialValues = {
    price_min: queryParams.find(PRICE_NEW_GTE) || queryParams.find(PRICE_USED_GTE),
    price_max: queryParams.find(PRICE_NEW_LTE) || queryParams.find(PRICE_USED_LTE)
  };

  useEffect(() => {
    const toCheck = [
      PRICE_USED_GTE,
      PRICE_USED_LTE,
      PRICE_NEW_GTE,
      PRICE_NEW_LTE
    ]
    let hasValue = false
    toCheck.forEach(param => {
      if (queryParams.find(param) !== undefined)
        hasValue = true
    })

    if (hasValue) {
      if (queryParams.find(QUERY_PARAM_PRODUCT_CONDITION) === undefined) {
        // All
        let allGood = true
        toCheck.forEach(param => {
          if (queryParams.find(param) === undefined)
            allGood = false
        })
        if (!allGood) {
          document.getElementById("price-submit-btn").click()
        }
      } else {
        // New
        if (condition === conditions.NEW) {
          if (
              queryParams.find(PRICE_USED_GTE) === undefined &&
              queryParams.find(PRICE_USED_LTE) === undefined &&
              queryParams.find(PRICE_NEW_GTE) !== undefined &&
              queryParams.find(PRICE_NEW_LTE) !== undefined
          ) {
            // new is good
          } else {
            document.getElementById("price-submit-btn").click()
          }
        }
        // Used
        else {
          if (
              queryParams.find(PRICE_USED_GTE) !== undefined &&
              queryParams.find(PRICE_USED_LTE) !== undefined &&
              queryParams.find(PRICE_NEW_GTE) === undefined &&
              queryParams.find(PRICE_NEW_LTE) === undefined
          ) {
            // used is good
          } else {
            document.getElementById("price-submit-btn").click()
          }
        }
      }
    }
  }, [location.search, condition, queryParams])

  /**
   * Handles price form submit to update query params according with the selected condition
   * @param values
   */
  function onSubmit(values) {
    let newQueryParams = {}

    switch (condition) {
      case conditions.NEW:
        newQueryParams[PRICE_NEW_GTE] = values.price_min;
        newQueryParams[PRICE_NEW_LTE] = values.price_max;
        newQueryParams[PRICE_USED_GTE] = null;
        newQueryParams[PRICE_USED_LTE] = null;
        break;
      case conditions.USED:
        newQueryParams[PRICE_NEW_GTE] = null;
        newQueryParams[PRICE_NEW_LTE] = null;
        newQueryParams[PRICE_USED_GTE] = values.price_min;
        newQueryParams[PRICE_USED_LTE] = values.price_max;
        break;
      default:
        newQueryParams[PRICE_NEW_GTE] = values.price_min;
        newQueryParams[PRICE_USED_GTE] = values.price_min;
        newQueryParams[PRICE_NEW_LTE] = values.price_max;
        newQueryParams[PRICE_USED_LTE] = values.price_max;
        break;
    }

    queryParams.update(newQueryParams);
    history.push({
      pathname: "/store/products",
      search: queryParams.asSearchString
    })
  }

  return (
      <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}>
        {
          ({values}) => (
              <Form
                  id="price-form"
                  className="form"
                  noValidate>
                <PanelGroup
                    id="price"
                    defaultActiveKey="1" accordion>
                  <Panel
                      eventKey="1"
                      header={<h6>Price<i className="fa fa-caret-up"/></h6>}>
                    <div className="panel-group__item">
                      <div className="row">
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <div className="form-field">
                              <Field
                                  id="price_min"
                                  aria-label="minimum price"
                                  name="price_min"
                                  type="number"
                                  className="form-control"
                                  placeholder="$Min"/>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <div className="form-field">
                              <Field
                                  id="price_max"
                                  name="price_max"
                                  aria-label="maximum price"
                                  type="number"
                                  className="form-control"
                                  placeholder="$Max"/>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4" style={{padding: "0", textAlign: "center"}}>
                          <button
                              id="price-submit-btn"
                              type="submit"
                              className=""
                              style={{margin: "0"}}
                              disabled={!values.price_min || !values.price_max}>
                            Go
                          </button>
                        </div>
                      </div>
                    </div>
                  </Panel>
                </PanelGroup>
              </Form>
          )
        }
      </Formik>
  );
}