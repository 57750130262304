import React, {useState} from 'react';
import CartItemListContainer from "modules/store/scenes/Cart/components/CartItemList/container";
import {Dropdown} from "react-bootstrap";
import "./styles/HeaderCartDropdown.scss";
import classNames from "classnames";
import {Link} from "react-router-dom";

export default function HeaderCartDropdown({store}) {
  const [open, setOpen] = useState(false);
  const {cart} = store;

  return (
      <Dropdown id="header-cart-dropdown">
        <Dropdown.Toggle
            id="header-cart-dropdown"
            aria-label="cart-dropdown"
            open={open}
            onToggle={() => setOpen(!open)}
            className="header-cart-dropdown">
            <span className={classNames({
                "header-cart-dropdown__icon icon-Cart": true,
                "header-cart-dropdown__icon--with-counter": cart.cart_items.length > 0
            })}>
            CART
            <span className={classNames({
                "hidden": cart.cart_items.length === 0,
                "header-cart-dropdown__counter": true
            })}>
              {cart.cart_items.length}
            </span>
          </span>
        </Dropdown.Toggle>
          <Dropdown.Menu
              style={{
                  left: "auto",
                  right: 0
              }}>
          <CartItemListContainer/>
          <div className="options">
              <p>
                  <Link to="/store/cart" onClick={() => setOpen(false)}><span
                      className="gray">View Cart</span></Link>
                  <span className="vline"/>
                  <Link to="/store/checkout" onClick={() => setOpen(false)}><span className="blue">Checkout</span></Link>
            </p>
          </div>
        </Dropdown.Menu>
      </Dropdown>
  );
};