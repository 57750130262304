import React from 'react';
import NewsletterSubscribeContainer from "components/NewsletterSubscribe/container";
import AboutUs from "./scenes/AboutUs";
import OurHistory from "./scenes/OurHistory";
import FAQ from "./scenes/FAQ";
import {NavLink, Route} from "react-router-dom";
import PropTypes from "prop-types";
import "./styles/About.scss";
import CareerOpportunities from "./scenes/CareerOpportunities";
import PrivacyPolicy from "./scenes/PrivacyPolicy";
import TermsOfUse from "./scenes/TermsOfUse";
import ReturnPolicy from "./scenes/ReturnPolicy";

const About = ({match}) => (
    <div>
      <section className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-3">
              <ul id="sidebar" className="nav nav-pills nav-stacked">
                <li>
                  <NavLink to={match.url} activeClassName="active" exact>
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink to={match.url + '/our-history'} activeClassName="active">
                    Our History
                  </NavLink>
                </li>
                <li>
                  <NavLink to={match.url + '/now-hiring'} activeClassName="active">
                    Career Opportunities
                  </NavLink>
                </li>
                <li>
                  <NavLink to={match.url + '/faq'} activeClassName="active">
                    FAQ
                  </NavLink>
                </li>
                <li>
                  <NavLink to={match.url + '/privacy-policy'} activeClassName="active">
                    Privacy Policy
                  </NavLink>
                </li>
                <li>
                  <NavLink to={match.url + '/return-policy'} activeClassName="active">
                    Return Policy
                  </NavLink>
                </li>
                <li>
                  <NavLink to={match.url + '/terms-of-use'} activeClassName="active">
                    Terms of Use
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-md-9 col-sm-9">
              <div className="tab-content">
                <Route exact path={match.url} component={AboutUs}/>
                <Route path={match.url + '/our-history'} component={OurHistory}/>
                <Route path={match.url + '/now-hiring'} component={CareerOpportunities}/>
                <Route path={match.url + '/faq'} component={FAQ}/>
                <Route path={match.url + '/privacy-policy'} component={PrivacyPolicy}/>
                <Route path={match.url + '/terms-of-use'} component={TermsOfUse}/>
                <Route path={match.url + '/return-policy'} component={ReturnPolicy}/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NewsletterSubscribeContainer/>
      </div>
);

About.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  })
};

export default About;
