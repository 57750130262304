import React, {useEffect, useState} from 'react';
import _ from "lodash";
import {GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs} from "react-google-maps";
import pin from './assets/img/pin.png';
import {getStoreTypeDisplay} from "modules/Locations/index";

export function AsyncGoogleMap({locations, activeLocation, setActiveLocation}) {
  const [map, setMap] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded && (locations !== null && locations.length > 0 && map))
      fitGoogleMapZoomAndCenter();
  });

  useEffect(() => {
    if (activeLocation && map) {
      const location = _.find(locations, {id: activeLocation.id});
      if (location)
        map.panTo(location.marker.getPosition());
    }
  });

  /**
   * Fits bounds when locations are updated
   */
  function fitGoogleMapZoomAndCenter() {
    const bounds = new window.google.maps.LatLngBounds();

    let loc = null;
    for (let i = 0; i < locations.length; i++) {
      loc = new window.google.maps.LatLng(locations[i].geo_lat, locations[i].geo_long);
      bounds.extend(loc);
    }

    map.fitBounds(bounds);
    map.panToBounds(bounds);
    setIsLoaded(true);
  }

  /**
   * Handles click on map markers
   * @param clickedLocation
   */
  function handleMarkerClick(clickedLocation) {
    setActiveLocation(clickedLocation);
  }

  /**
   * Handles click on close info window buttons
   * @param clickedLocation
   */
  function handleCloseClick(clickedLocation) {
    setActiveLocation(null);
  }

  return (
      <GoogleMap
          ref={ref => setMap(ref)}
          options={{
            mapTypeControl: false,
            fullscreenControl: false
          }}
          center={{
            lat: 37.05578,
            lng: -94.515577
          }}
          defaultZoom={20}>
        {
          locations && locations.map((location, index) => (
              <Marker
                  key={index}
                  icon={pin}
                  onClick={() => handleMarkerClick(location)}
                  title={`${location.address_1}, ${location.name}, ${location.region_name}`}
                  position={{lat: location.geo_lat, lng: location.geo_long}}
                  ref={ref => location.marker = ref}>
                {
                  (activeLocation && activeLocation.id === location.id) && (
                      <InfoWindow
                          onCloseClick={() => handleCloseClick(location)}>
                        <div>
                          <h4>{getStoreTypeDisplay(location.store_type)} - {location.city_name}</h4>
                        </div>
                      </InfoWindow>
                  )
                }
              </Marker>
          ))
        }
      </GoogleMap>
  )
}

export default withScriptjs(withGoogleMap(AsyncGoogleMap))