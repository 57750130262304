// Use this as a reference of the available query params to filter products
// All of these query params are React side, not the ones being sent to the API.
export const QUERY_PARAM_PAGE = 'page'
export const QUERY_PARAM_PAGE_SIZE = 'page_size'
export const QUERY_PARAM_SEARCH = 'search'
export const QUERY_PARAM_ORDERING = 'ordering'
export const QUERY_PARAM_LIMIT = 'limit'
export const FROM = 'from'

//AdvancedSearch Parameters received
export const QUERY_PARAM_CATEGORIES = 'categories'
export const QUERY_PARAM_DESCRIPTION = 'description__search'
export const QUERY_PARAM_NAME = 'name__search'
export const QUERY_PARAM_MEDIA_TYPE = 'media_type'
export const QUERY_PARAM_FORMAT = 'format'
export const QUERY_PARAM_GENRES = 'genres'
export const QUERY_PARAM_GAMING_PLATFORM = 'gamingproduct__platform'
export const QUERY_PARAM_ESRB_RATING = 'esrb_rating'
export const QUERY_PARAM_MPAA_RATING = 'mpaa_rating'
export const QUERY_PARAM_PLATFORM = 'platform'
export const VIEW_TYPE = 'view_type'
export const QUERY_PARAM_VIEW_TYPE = 'view_type'
export const QUERY_PARAM_SKU = 'sku'
export const QUERY_PARAM_CAST = 'cast'
export const QUERY_PARAM_NEW_THIS_WEEK = 'new-this-week'
export const QUERY_PARAM_MOST_VIEWED = 'most-viewed'
export const QUERY_PARAM_COMING_SOON = 'coming-soon'
// This query param is wrongly named in back-end, but in the front-end we'll always treat it as condition for consistency
export const QUERY_PARAM_PRODUCT_CONDITION = 'product_status'
export const PRICE_GTE = 'price__gte'
export const PRICE_LTE = 'price__lte'
export const PRICE_NEW_GTE = 'price_new__gte'
export const PRICE_USED_GTE = 'price_used__gte'
export const PRICE_NEW_LTE = 'price_new__lte'
export const PRICE_USED_LTE = 'price_used__lte'
export const QUERY_PARAM_IN_STOCK = 'in_stock'
export const QUERY_PARAM_PICKUP_AVAILABLE = 'pickup_available'
export const QUERY_PARAM_STORES = 'stores'
export const QUERY_PARAM_YEAR = 'year'

export const PRICE_QUERY_PARAMS = ["price_new", "-price_new", "price_used", "-price_used"]
