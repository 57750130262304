import {
  QUERY_PARAM_CATEGORIES,
  QUERY_PARAM_DESCRIPTION,
  QUERY_PARAM_NAME,
  QUERY_PARAM_MEDIA_TYPE,
  QUERY_PARAM_FORMAT,
  QUERY_PARAM_GENRES,
  QUERY_PARAM_GAMING_PLATFORM,
  QUERY_PARAM_ESRB_RATING,
  QUERY_PARAM_MPAA_RATING,
  QUERY_PARAM_PLATFORM,
  VIEW_TYPE,
  QUERY_PARAM_VIEW_TYPE,
  QUERY_PARAM_SKU,
  QUERY_PARAM_CAST,
  QUERY_PARAM_NEW_THIS_WEEK,
  QUERY_PARAM_MOST_VIEWED,
  QUERY_PARAM_COMING_SOON,
  QUERY_PARAM_SEARCH,
  QUERY_PARAM_ORDERING,
  QUERY_PARAM_YEAR
} from "modules/store/scenes/ProductsList/constants/query-params";

export const NEW_THIS_WEEK = 'New Releases';
export const NEW_THIS_WEEK_SLUG = 'new-this-week';
export const MOST_VIEWED = 'Most Viewed';
export const MOST_VIEWED_SLUG = 'most-viewed';
export const COMING_SOON = 'Coming Soon';
export const COMING_SOON_SLUG = 'coming-soon';
export const ALL = 'All';
export const MOVIES = 'Movies';
export const GAMES = 'Games';
export const POSTERS = 'Posters';
export const TOYS = 'Toys';
export const MUSIC = 'Music';
export const GIFT_CARDS = 'Gift Cards';
export const RECENTLY_VIEWED = 'Recently Viewed';

export const DEFAULT_QUERY_PARAMS = {
  [QUERY_PARAM_CATEGORIES]: null,
  [QUERY_PARAM_SEARCH]: null,
  [QUERY_PARAM_GENRES]: null,
  [QUERY_PARAM_DESCRIPTION]: null,
  [QUERY_PARAM_NAME]: null,
  [QUERY_PARAM_MEDIA_TYPE]: null,
  [QUERY_PARAM_FORMAT]: null,
  [QUERY_PARAM_GAMING_PLATFORM]: null,
  [QUERY_PARAM_ESRB_RATING]: null,
  [QUERY_PARAM_MPAA_RATING]: null,
  [QUERY_PARAM_PLATFORM]: null,
  [VIEW_TYPE]: null,
  [QUERY_PARAM_VIEW_TYPE]: null,
  [QUERY_PARAM_SKU]: null,
  [QUERY_PARAM_CAST]: null,
  [QUERY_PARAM_NEW_THIS_WEEK]: null,
  [QUERY_PARAM_MOST_VIEWED]: null,
  [QUERY_PARAM_COMING_SOON]: null,
  [QUERY_PARAM_ORDERING]: null,
  [QUERY_PARAM_YEAR]: null
};

export const PRODUCT_CATEGORIES = [
  {
    id: NEW_THIS_WEEK_SLUG,
    name: NEW_THIS_WEEK,
    slug: VIEW_TYPE,
    reactQueryParams: {
      ...DEFAULT_QUERY_PARAMS,
      [VIEW_TYPE]: QUERY_PARAM_NEW_THIS_WEEK,
    },
    customHeaderHtml: null
  },
  // {
  //   id: MOST_VIEWED_SLUG,
  //   name: MOST_VIEWED,
  //   slug: VIEW_TYPE,
  //   reactQueryParams: {
  //     [VIEW_TYPE]: QUERY_PARAM_MOST_VIEWED,
  //     [QUERY_PARAM_CATEGORIES]: null,
  //     [QUERY_PARAM_SEARCH]: null
  //   },
  //   customHeaderHtml: null
  // },
  // {
  //   id: COMING_SOON_SLUG,
  //   name: COMING_SOON,
  //   slug: VIEW_TYPE,
  //   reactQueryParams: {
  //     [VIEW_TYPE]: QUERY_PARAM_COMING_SOON,
  //     [QUERY_PARAM_CATEGORIES]: null,
  //     [QUERY_PARAM_SEARCH]: null
  //   },
  //   customHeaderHtml: null
  // },
  // {
  //   id: null,
  //   name: ALL,
  //   slug: ALL,
  //   to: '/store/products',
  //   reactQueryParams: {
  //     [ALL]: null
  //   },,
  //   [QUERY_PARAM_SEARCH]: null
  //   customHeaderHtml: null
  // },
  {
    id: 23,
    name: MOVIES,
    slug: QUERY_PARAM_CATEGORIES,
    counter: 0,
    apiUrl: '/products/movies/',
    reactQueryParams: {
      ...DEFAULT_QUERY_PARAMS,
      [QUERY_PARAM_CATEGORIES]: 23,
    },
    customHeaderHtml: null
  },
  {
    id: 32,
    name: GAMES,
    slug: QUERY_PARAM_CATEGORIES,
    counter: 0,
    apiUrl: '/products/games/',
    reactQueryParams: {
      ...DEFAULT_QUERY_PARAMS,
      [QUERY_PARAM_CATEGORIES]: 32,
    },
    items: [
      { name: 'Console', [QUERY_PARAM_CATEGORIES]: 26 },
      { name: 'Pc', [QUERY_PARAM_CATEGORIES]: 27 },
      { name: 'Handheld Devices', [QUERY_PARAM_CATEGORIES]: 30 }
    ],
    customHeaderHtml: null
  },
  {
    id: 34,
    name: POSTERS,
    slug: QUERY_PARAM_CATEGORIES,
    counter: 0,
    apiUrl: '/products/posters/',
    reactQueryParams: {
      ...DEFAULT_QUERY_PARAMS,
      [QUERY_PARAM_CATEGORIES]: 34,
    },
    customHeaderHtml: null
  },
  {
    id: 33,
    name: TOYS,
    slug: QUERY_PARAM_CATEGORIES,
    counter: 0,
    apiUrl: '/products/toys/',
    reactQueryParams: {
      ...DEFAULT_QUERY_PARAMS,
      [QUERY_PARAM_CATEGORIES]: 33,
    },
    customHeaderHtml: null
  },
  {
    id: 49,
    name: MUSIC,
    slug: QUERY_PARAM_CATEGORIES,
    counter: 0,
    apiUrl: '/products/music',
    reactQueryParams: {
      ...DEFAULT_QUERY_PARAMS,
      [QUERY_PARAM_CATEGORIES]: 49,
    },
    customHeaderHtml: null
  },
  {
    id: 'gift-cards',
    name: GIFT_CARDS,
    slug: 'gift-cards',
    to: '/gift-cards',
    customHeaderHtml: null
  }
];
