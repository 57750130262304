import React, {useState} from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { slugify } from "@vintagestock/vintage-common-components"
import "./styles/NavTabs.scss"

export default function NavTabs({tabs, defaultIndex, activeIndex, onChange}) {
  const [active, setActive] = useState(defaultIndex)
  const controlled = activeIndex !== undefined && onChange
  const currentIndex = controlled ? activeIndex : active

  /**
   * Handles click on tabs to activate them
   * @param event {Object}
   * @param index {Number}
   */
  function handleTabClick(event, index) {
    if (controlled)
      onChange(index)
    else
      setActive(index)
  }

  // TODO Is it a good idea to throw errors in React?
  if (currentIndex < 0 || currentIndex + 1 > tabs.length)
    throw new Error('Given index is out of range')

  return (
      <div className="navigation-tabs">
        <ul className="nav nav-tabs" role="tablist">
          {
            tabs.map((tab, index) =>
                <li
                    key={index}
                    id={slugify(tab.title)}
                    role="tab"
                    className={currentIndex === index ? "active" : ""}>
                  <div
                      onClick={event => handleTabClick(event, index)}>
                    {tab.title}
                  </div>
                </li>
            )
          }
        </ul>
        <div className="tab-content">
          <div className="tab-pane active">
            {
              _.isFunction(tabs[currentIndex].content)
                  ?
                  tabs[currentIndex].content()
                  :
                  tabs[currentIndex].content
            }
          </div>
        </div>
      </div>
  )
}

NavTabs.propTypes = {
  defaultIndex: PropTypes.number,
  activeIndex: PropTypes.number,
  onChange: PropTypes.func,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
      PropTypes.func
    ])
  })).isRequired
}

NavTabs.defaultProps = {
  defaultIndex: 0
}